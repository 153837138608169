import React from "react";
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="container">
      <h1 className="page-heading">Privacy Policy</h1>
      <div className="first-paragraph">
        <h3 className="first-heading">
          Protecting Your Privacy on Our Website
        </h3>
        <p className="first-p">
          Radiant RCM is dedicated to safeguarding your privacy on our website, <span className="highlight">https://radiantrcm.com</span>. This policy outlines our practices regarding the collection, use, and protection of personal information. We prioritize transparency and provide users with control over their data. This policy also details how you can access and update your information.
        </p>
      </div>
      <div className="second-paragraph">
        <h3 className="second-heading">Personal Information</h3>
        <p className="personal-info">
          Personal information refers to identifiable details such as names, addresses, email addresses, and phone numbers. We do not collect data from users under 13 years old.
        </p>
        <h4 className="site">Website</h4>
        <p className="site-info">
          This policy applies to the website <span className="highlight">https://radiantrcm.com</span>.
        </p>
      </div>
      <div className="third-paragraph">
        <h3 className="user">Users</h3>
        <p className="user-info">
          Users are individuals who visit and use our website. Personal data is collected when users register for a demo or intersted in our services. This includes names, email addresses, phone numbers, and company details. Additionally, we automatically collect information such as IP addresses.
        </p>
        <ol className="important-points">
          <li>Providing products and services</li>
          <li>Medical billing services</li>
          <li>Identification and authentication</li>
          <li>Improving services</li>
          <li>Research</li>
          <li>Anonymous reporting</li>
        </ol>
        <p className="explanation">
          We send essential service-related announcements via email. You can opt-out of promotional emails by following the unsubscribe link in those emails.
        </p>
        <h4 className="ip-heading">IP Addresses</h4>
        <p className="ip-info">
          We use IP addresses to diagnose technical issues and to estimate the number of users in specific geographic areas.
        </p>
      </div>
      <div className="four-paragraph">
        <h3 className="tech">Cookies and Tracking Technologies</h3>
        <p className="tech-info">
          We use cookies to improve user experience. "Session" cookies are deleted when you close your browser, while "persistent" cookies remain on your device for a longer period. You can reject cookies, but this may limit some functionalities on our website.
        </p>
        <h4 className="third-party">Third-Party Monitoring</h4>
        <p className="third-party-info">
          Our privacy policy does not cover the use of cookies by third parties. We use third-party tracking services to collect non-personally identifiable information about visitors.
        </p>
        <h4 className="targeting">Behavioral Targeting / Re-Targeting</h4>
        <p className="targeting-info">
          We partner with third-party ad networks to manage our advertising on other sites. These partners use cookies and web beacons to gather information about your activities on our website and others to provide targeted advertising.
        </p>
        <h4 className="info-sharing">Information Sharing and Disclosure</h4>
        <p className="info-sharing-content">
          Radiant RCM does not sell or share personal information for promotional purposes. We may disclose information to comply with legal requirements or protect our rights. If Radiant RCM is acquired, users will be notified before their information is transferred.
        </p>
      </div>
      <div className="fifth-paragraph">
        <h3 className="personal">Access, Update, or Correct Personal Information</h3>
        <p className="personal-info">
          You can update or delete your information by contacting us via telephone or email. We retain your data as long as your account is active or as needed to provide services.
        </p>
        <h4 className="security">Security</h4>
        <p className="security-info">
          We use industry-standard measures to protect your personal information. However, no method of transmission over the internet or electronic storage is 100% secure. For any security questions, contact us at <span className="highlight">info@radiantrcm.com</span>.
        </p>
        <h3 className="customer-testimonial">Customer Testimonials</h3>
        <p className="customer-testimonial-info">
          We display customer testimonials with their consent. To remove or update your testimonial, contact us at <span className="highlight">info@radiantrcm.com</span>.
        </p>
      </div>
      <h3 className="link">Links to Other Sites</h3>
      <p className="link-info">
        Our site may contain links to third-party websites. We are not responsible for the privacy practices of these sites. We encourage you to review their privacy policies.
      </p>
      <h3 className="refer">Referrals</h3>
      <p className="refer-info">
        If you refer others to our site, we will send an invitation email on your behalf. To remove this information, contact us at <span className="highlight">info@radiantrcm.com</span>.
      </p>
      <h3 className="data">Data Aggregation Services and De-identified Data</h3>
      <p className="data-info">
        We may use protected health information to provide data aggregation services and generate de-identified data as permitted by HIPAA. We retain ownership of this de-identified data.
      </p>
      <h3 className="changes">Changes to This Privacy Policy</h3>
      <p className="changes-info">
        We may update this privacy policy periodically. We encourage users to review this page for the latest information. For questions or suggestions, contact us at:
        <br />
        Phone: +1 918-383-6404
        <br />
        Email: <span className="highlight">info@radiantrcm.com</span>
      </p>
    </div>
  );
};

export default PrivacyPolicy;
