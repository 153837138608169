import React from "react";
import { PiClockCountdownFill } from "react-icons/pi";
import { FaCircleDollarToSlot, FaWpforms } from "react-icons/fa6";
import { BsGraphUpArrow, BsCollectionFill } from "react-icons/bs";
import { FaHandHoldingUsd } from "react-icons/fa";
import "./DifferenceCard.css";

const DifferenceCard = () => {
  return (
    <div className="DifferenceCardContainer">
      <div className="DifferenceCard">
        <BsCollectionFill />
        <p className="percentage">90 % +</p>
        <p className="name">Collection Ratio</p>
      </div>
      <div className="DifferenceCard">
        <FaWpforms />
        <p className="percentage">95 % </p>
        <p className="name">First Pass Clean Claims Rate</p>
      </div>
      <div className="DifferenceCard">
        <BsGraphUpArrow />
        <p className="percentage">15-20 %</p>
        <p className="name">Revenue Increase</p>
      </div>
      <div className="DifferenceCard">
        <FaCircleDollarToSlot />
        <p className="percentage">30 % </p>
        <p className="name">Reduction in A/R</p>
      </div>
      <div className="DifferenceCard">
        <FaHandHoldingUsd />
        <p className="percentage">&#9660; 10%</p>
        <p className="name">120+ AR</p>
      </div>
      <div className="DifferenceCard">
        <PiClockCountdownFill />
        <p className="percentage">7-14</p>
        <p className="name">Days Turnaround Time</p>
      </div>
    </div>
  );
};

export default DifferenceCard;
