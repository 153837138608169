import React from "react";
import money from '../Asset/images/money.jpg';
import decrease from '../Asset/images/decrease.jpg';
import increase from '../Asset/images/increase.jpg';
import './BenifitCard.css';

const BenifitCard = () => {
  return (
    <section className="section-with-cards">
      <h2>Benefits of Working with Radiant RCM</h2>
      <div className="card-container">
        <div className="benifit-card">
          <img src= {decrease} alt="money" className="benifitcard-image" />
          <h3>Decrease Costs:</h3>
          <p>
            Outsourcing your medical billing to Radiant RCM can lead to
            substantial cost savings. We only charge a modest percentage of the
            collections that we successfully process. Our healthcare revenue
            cycle management solutions are priced affordably, allowing us to
            cater to practices of varying sizes.
          </p>
        </div>
        <div className="benifit-card">
          <img src= {increase} alt="money" className="benifitcard-image" />
          <h3>Increase Revenue:</h3>
          <p>
            Radiant RCM guarantees the accurate and timely submission of your
            claims, effectively minimizing the occurrence of rejected or denied
            claims resulting from an inefficient medical billing process.
          </p>
        </div>
        <div className="benifit-card">
          <img src= {money} alt="money" className="benifitcard-image" />
          <h3>Get Paid Faster:</h3>
          <p>
            We offer dependable electronic medical billing solutions to ensure
            comprehensive claim submission to all potential carriers, with the
            capability to receive ERAs, thus further decreasing processing time.
          </p>
        </div>
      </div>
    </section>
  );
};

export default BenifitCard;
