import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaHospital, FaUserDoctor  } from "react-icons/fa6";
import { GiMicroscope, GiHospital } from "react-icons/gi";
import { IoIosUmbrella } from "react-icons/io";
import { BsCashCoin } from "react-icons/bs";
import { TbDental } from "react-icons/tb";
import { FaAmbulance, FaBriefcaseMedical } from "react-icons/fa";
import './ServiceSection.css';

const ServiceSection = ({showButton}) => {
  const navigate = useNavigate();

  const handleService = () => {
    navigate('/services');
  }
  return (
    <section className="services-section">
    <h2>Radiant RCM Revenue Cycle Management Services</h2>
    <div className="service-cards">
      <div className="service-card">
        <FaHospital  className="service-icon" />
        <p>Hospital Billing Services</p>
        {/* <a href="#" className="read-more-link">Read More</a> */}
      </div>
      <div className="service-card">
        <GiMicroscope className="service-icon" />
        <p>Laboratory Billing Services</p>
        {/* <a href="#" className="read-more-link">Read More</a> */}
      </div>
      <div className="service-card">
        <GiHospital  className="service-icon" />
        <p>Imaging Center Billing </p>
        {/* <a href="#" className="read-more-link">Read More</a> */}
      </div>
      <div className="service-card">
        <IoIosUmbrella  className="service-icon" />
        <p>Coverage Discovery</p>
        {/* <a href="#" className="read-more-link">Read More</a> */}
      </div>
      <div className="service-card">
       <FaUserDoctor   className="service-icon" />
        <p>Physician Billing Services</p>
        {/* <a href="#" className="read-more-link">Read More</a> */}
      </div>
      <div className="service-card">
        <BsCashCoin  className="service-icon" />
        <p>AR Recovery Service</p>
        {/* <a href="#" className="read-more-link">Read More</a> */}
      </div>
      <div className="service-card">
        <FaAmbulance className="service-icon" />
        <p>Ambulatory Surgery Billing</p>
        {/* <a href="#" className="read-more-link">Read More</a> */}
      </div>
      <div className="service-card">
        <FaBriefcaseMedical className="service-icon" />
        <p>Medical Billing</p>
        {/* <a href="#" className="read-more-link">Read More</a> */}
      </div>
      <div className="service-card">
        <TbDental  className="service-icon" />
        <p>Dental Billing</p>
        {/* <a href="#" className="read-more-link">Read More</a> */}
      </div>
    </div>
    {showButton && <button className="view-services-btn" onClick={handleService}>View All Services</button>}
  </section>
  )
}

export default ServiceSection;