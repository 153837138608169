import React, { useState, useEffect } from 'react';
import './FullBlogPost.css';

const FullBlogPost = ({ postId}) => {
  const [post, setPost] = useState(null);

  useEffect(() => {
    console.log('postId:', postId);
    const fetchSinglePost = async () => {
      try {
        const response = await fetch(`https://api.radiantrcm.com/single-posts/${postId}`);
        if (response.ok) {
          const data = await response.json();
          setPost(data);
        } else {
          console.error('Failed to fetch single blog post');
        }
      } catch (error) {
        console.error('Error fetching single blog post:', error);
      }
    };

    fetchSinglePost();
  }, [postId]);

  if (!post) {
    return <div>Loading...</div>; 
  }

  return (
    <div className="full-blog-post-container">
      <div className="full-blog-post-header">
        <h1>{post.title}</h1>
        <p>Author: {post.author}</p>
        <p>Date: {post.date}</p>
      </div>
      <div className="full-blog-post-content">
      <img src={`data:image/jpeg;base64,${post.image}`} alt={post.title} className="blog-post-image" />
        <p>{post.excerpt}</p>
      </div>
    </div>
  );
};

export default FullBlogPost;

