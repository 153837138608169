import React from "react";
import excellence from "../Asset/icons/excellence.png";
import integrity from "../Asset/icons/integrity.png";
import collaboration from "../Asset/icons/collaboration.png";
import innovation from "../Asset/icons/innovation.png";
import empathy from "../Asset/icons/empathy.png";
import responsiveness from "../Asset/icons/responsiveness.png";
import "./ValuesCard.css";

const ValuesCard = () => {
  return (
    <div className="values-card-container">
      <div className="values-Card">
        <img src={excellence} alt="Excellence" className="value-icon" />
        <h2 className="value-heading"> Excellence </h2>
        <p className="description">
          {" "}
          We are driven by a commitment to excellence in everything we do. From
          crafting innovative solutions to delivering unparalleled customer
          service, we strive to surpass expectations and set new industry
          standards.{" "}
        </p>
      </div>
      <div className="values-Card">
        <img src={integrity} alt="Integrity" className="value-icon" />
        <h2 className="value-heading"> Integrity </h2>
        <p className="description">
          {" "}
          Integrity is the foundation of our operations. We uphold the highest
          ethical standards in all interactions, ensuring transparency, honesty,
          and trustworthiness in our relationships with clients, partners, and
          team members.{" "}
        </p>
      </div>
      <div className="values-Card">
        <img src={collaboration} alt="Collaboration" className="value-icon" />
        <h2 className="value-heading"> Collaboration </h2>
        <p className="description">
          {" "}
          We believe in the power of collaboration. By working closely with our
          clients, partners, and team members, we leverage diverse perspectives
          and insights to create comprehensive solutions that drive success for
          all stakeholders.{" "}
        </p>
      </div>
      <div className="values-Card">
        <img src={innovation} alt="Innovation" className="value-icon" />
        <h2 className="value-heading"> Innovation </h2>
        <p className="description">
          {" "}
          We embrace innovation as a driving force. Continuously seeking new
          ways to enhance processes, leverage technology, and evolve our
          services, we remain at the forefront of healthcare financial
          management.{" "}
        </p>
      </div>
      <div className="values-Card">
        <img src={empathy} alt="Empathy" className="value-icon" />
        <h2 className="value-heading"> Empathy </h2>
        <p className="description">
          {" "}
          Empathy is at the heart of our approach. We understand the challenges
          faced by healthcare providers and tailor our solutions to address
          their unique needs, ensuring a seamless experience that fosters trust
          and long-lasting relationships.{" "}
        </p>
      </div>
      <div className="values-Card">
        <img src={responsiveness} alt="Responsiveness" className="value-icon" />
        <h2 className="value-heading"> Responsiveness </h2>
        <p className="description">
          {" "}
          Timeliness is of the essence. We prioritize swift responses and
          proactive solutions to ensure that our clients' concerns are addressed
          promptly and their operations remain uninterrupted, fostering strong
          partnerships built on reliability.{" "}
        </p>
      </div>
    </div>
  );
};

export default ValuesCard;
