import React, { useState, useEffect } from 'react';
import Ana from '../Asset/images/Ana.jpg';
import Jhon from '../Asset/images/Jhon.jpg';
import Eli from '../Asset/images/Eli.jpg';
import Linda from '../Asset/images/Linda.jpg';
import Rik from '../Asset/images/Rik.jpg';
import './Testimonial.css';

const Testimonial = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isAutoPlaying, setIsAutoPlaying] = useState(true);

  const testimonials = [
    {
      id: 1,
      image: Ana,
      cotation: '“Have truly transformed our practice”',
      feedback: `Radiant RCM's services have exceeded our expectations. Their attention to detail in claims submission is impressive, and their patient recovery assistance has positively impacted our revenue stream.`,
      name: 'Dr. Emily Roberts',
      occupation: 'CEO of HealthFirst Clinic',
    },
    {
      id: 2,
      image: Jhon,
      cotation: '“More time to focus on our patients”',
      feedback: 'Switching to Radiant RCM was a game-changer for us. Their billing expertise helped us recover lost revenue, and their efficient processes saved us time and money. Highly recommended!',
      name: 'Dr. Alex Thompson',
      occupation: 'Family Physician',
    },
    {
      id: 3,
      image: Eli,
      cotation: '“A true game changer in healthcare”',
      feedback: 'Radiant RCM Inc`s meticulous handling of transactions enables me to concentrate wholly on delivering compassionate pediatric care.',
      name: 'Dr. Emily Chen',
      occupation: ' Pediatrician',
    },
    {
      id: 4,
      image: Linda,
      cotation: '“Have truly transformed our practice”',
      feedback: 'Under the precise oversight of Radiant RCM Inc, I am empowered to prioritize the restoration of mobility with unwavering focus',
      name: 'Dr. Michael Roberts',
      occupation: 'Orthopedic Surgeon',
    },
    {
      id: 5,
      image: Rik,
      cotation: '“Have truly transformed our practice”',
      feedback: 'With Radiant RCM Inc managing billing intricacies, I am liberated to steadfastly concentrate on fostering cardiovascular wellness and patient outcomes',
      name: 'Dr. David Nguyen',
      occupation: 'Cardiologist',
    },
  ];

  useEffect(() => {
    let intervalId;
    if (isAutoPlaying) {
      intervalId = setInterval(() => {
        goToNextSlide();
      }, 5000); // Autoplay interval is set to 5 seconds (5000 milliseconds)
    }
    return () => clearInterval(intervalId);
  }, [currentSlide, isAutoPlaying]);

  const goToNextSlide = () => {
    const nextSlide = (currentSlide + 1) % testimonials.length;
    setCurrentSlide(nextSlide);
  };

  const goToPrevSlide = () => {
    const prevSlide = (currentSlide - 1 + testimonials.length) % testimonials.length;
    setCurrentSlide(prevSlide);
  };

  const handleDotClick = (index) => {
    setCurrentSlide(index);
    setIsAutoPlaying(false); // Pause autoplay when manually navigating
  };

  return (
    <section className="testimonial-section">
      <h2>What Our Clients Say</h2>
      <div className="slideshow">
        {testimonials.map((testimonial, index) => (
          <div
            key={testimonial.id}
            className={index === currentSlide ? 'slide active' : 'slide'}
          >
            <div className="customer-image-container">
              <img src={testimonial.image} alt="Customer" className="customer-image" />
            </div>
            <div className="feedback-container">
              <h3 className='cotation'>{testimonial.cotation}</h3>
              <p className="customer-feedback">{testimonial.feedback}</p>
              <hr className="testimonial-divider" />
              <p className="client-info">
                <strong>{testimonial.name}</strong>, {testimonial.occupation}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className="pagination">
        {testimonials.map((_, index) => (
          <span
            key={index}
            className={index === currentSlide ? 'dot active' : 'dot'}
            onClick={() => handleDotClick(index)}
          />
        ))}
      </div>
    </section>
  );
};

export default Testimonial;


