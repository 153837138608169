import React from "react";
import { NavLink } from "react-router-dom";
import {
  FaFacebook,
  FaLinkedin,
  FaInstagram,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import "./Footer.css";

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="footer-section">
          <h3>Our Services</h3>
          <ul>
            <li>Hospital Billing Services</li>
            <li>Laboratory Billing Services</li>
            <li>Imaging Center Billing</li>
            <li>Coverage Discovery</li>
            <li>Physician Billing Services</li>
            <li>AR Recovery Service</li>
            <li>Ambulatory Surgery Billing</li>
            <li>Medical Billing</li>
          </ul>
        </div>
        <div className="footer-section">
          <h3>Specialties</h3>
          <ul>
            <li>Cardiology</li>
            <li>Dermatology</li>
            <li>Rehab</li>
            <li>Oncology</li>
            <li>Psychiatric</li>
            <li>Wound Care</li>
            <li>Pathology</li>
            <li>Urology</li>
          </ul>
        </div>
        <div className="footer-section">
          <h3>Quick Links</h3>
          <ul>
            <li>
              <NavLink to="/about">About</NavLink>
            </li>
            <li>
              <NavLink to="/services">Services</NavLink>
            </li>
            <li>
              <NavLink to="/blog">Blog</NavLink>
            </li>
            <li>
              <NavLink to="/contact">Contact</NavLink>
            </li>
            <li>
              <NavLink to="/privacypolicy">Privacy Policy</NavLink>
            </li>
          </ul>
        </div>
      </footer>
      <div className="footer-bottom">
        <p>© 2024 Radiant RCM. All rights reserved.</p>
        <div className="social-icons">
          <FaFacebook className="social-icon" />
          <FaLinkedin className="social-icon" />
          <FaInstagram className="social-icon" />
          <FaTwitter className="social-icon" />
          <FaYoutube className="social-icon" />
        </div>
      </div>
    </>
  );
};

export default Footer;
