import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import ColorLogo from "../Asset/images/ColorLogo.svg";
import "./Header.css";

const Header = ({ isLoggedIn, toggleLogin }) => {
  const navigate = useNavigate();

  const handleDemo = () => {
    navigate("/requestdemo");
  };

  const handleLogout = () => {
    localStorage.removeItem("isLoggedIn");
    toggleLogin(null);
    navigate("/");
  };

  return (
    <header className="sticky-header">
      <div className="logo">
        <img src={ColorLogo} alt="Logo" />
      </div>
      <nav className="navbar">
        <ul>
          <li>
            <NavLink to="/" activeClassName="active" exact="true">
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/about" activeClassName="active">
              About
            </NavLink>
          </li>
          <li>
            <NavLink to="/services" activeClassName="active">
              Services
            </NavLink>
          </li>
          <li>
            <NavLink to="/blog" activeClassName="active">
              Blog
            </NavLink>
          </li>
          <li>
            <NavLink to="/contact" activeClassName="active">
              Contact
            </NavLink>
          </li>
          {isLoggedIn && (
            <li>
              <NavLink to="/addblog" activeClassName="active">
                AddBlog
              </NavLink>
            </li>
          )}
        </ul>
      </nav>
      {isLoggedIn ? (
        <button className="logout-btn" onClick={handleLogout}>
          Logout
        </button>
      ) : (
        <button className="schedule-demo-btn" onClick={handleDemo}>
          Request Demo
        </button>
      )}
    </header>
  );
};

export default Header;

