// import React, { useState } from 'react';
// import { Form, Input, Button, message } from 'antd';
// import BlosgPostData from '../Blog Data/BlosgPostData';
// import './AddBlog.css';

// const AddBlogForm = () => {
//   const [form] = Form.useForm();
//   const [imageUrl, setImageUrl] = useState('');

//   const onFinish = async values => {
//     const newPost = {
//       title: values.title,
//       excerpt: values.excerpt,
//       author: values.author,
//       date: values.date,
//       categories: values.categories.split(',').map(category => category.trim()),
//       image: imageUrl
//     };

//     try {
//       const response = await fetch('https://api.radiantrcm.com/blog-posts', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json'
//         },
//         body: JSON.stringify(newPost)
//       });

//       if (response.ok) {
//         // Add the new post to the data array
//         BlosgPostData.push(newPost);
//         console.log(BlosgPostData); // Display the updated data in the console

//         // Clear form fields
//         form.resetFields();

//         // Display success message
//         message.success('Blog post added successfully');
//       } else {
//         // Display error message if request fails
//         message.error('Failed to add blog post');
//       }
//     } catch (error) {
//       console.error('Error adding blog post:', error);
//       message.error('Failed to add blog post');
//     }
//   };

//   const onFinishFailed = errorInfo => {
//     console.log('Failed:', errorInfo);
//   };

//   const handleImageUpload = async e => {
//     const file = e.target.files[0];
//     if (!file) return; // Check if file is present
  
//     const isJpgOrPngOrSvg = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';
//     if (!isJpgOrPngOrSvg) {
//       message.error('You can only upload JPG/PNG/SVG file!');
//       return false; // Return false to prevent upload
//     }
  
//     try {
//       // Read the image file as a base64 string
//       const base64String = await getBase64(file);
  
//       // Set the image URL to the base64 string
//       setImageUrl(base64String);
//     } catch (error) {
//       console.error('Error converting image to base64:', error);
//     }
  
//     return false; // Return false to prevent default upload behavior
//   };
  
  
//   // Function to convert image file to base64 string
//   const getBase64 = file => {
//     return new Promise((resolve, reject) => {
//       const reader = new FileReader();
//       reader.readAsDataURL(file);
//       reader.onload = () => resolve(reader.result);
//       reader.onerror = error => reject(error);
//     });
//   };
  

//   return (
//     <div className="add-blog-form-container">
//       <h1 className="add-blog-form-title">Add Blog Post</h1>
//       <Form
//         form={form}
//         name="add-blog-form"
//         onFinish={onFinish}
//         onFinishFailed={onFinishFailed}
//         labelCol={{ span: 6 }}
//         wrapperCol={{ span: 18 }}
//       >
//         <Form.Item
//           label="Title"
//           name="title"
//           rules={[{ required: true, message: 'Please enter the title' }]}
//           className="add-blog-form-item"
//         >
//           <Input className="add-blog-form-input" />
//         </Form.Item>

//         <Form.Item
//           label="Excerpt"
//           name="excerpt"
//           rules={[{ required: true, message: 'Please enter the excerpt' }]}
//           className="add-blog-form-item"
//         >
//           <Input.TextArea className="add-blog-form-input" />
//         </Form.Item>

//         <Form.Item
//           label="Author"
//           name="author"
//           rules={[{ required: true, message: 'Please enter the author' }]}
//           className="add-blog-form-item"
//         >
//           <Input className="add-blog-form-input" />
//         </Form.Item>

//         <Form.Item
//           label="Date"
//           name="date"
//           rules={[{ required: true, message: 'Please select the date' }]}
//           className="add-blog-form-item"
//         >
//           <Input type="date" className="add-blog-form-input" />
//         </Form.Item>

//         <Form.Item
//           label="Categories"
//           name="categories"
//           rules={[{ required: true, message: 'Please enter at least one category' }]}
//           className="add-blog-form-item"
//         >
//           <Input className="add-blog-form-input" />
//         </Form.Item>

//         <Form.Item
//           label="Image"
//           name="image"
//           rules={[{ required: true, message: 'Please upload an image' }]}
//           className="add-blog-form-item"
//         >
//            <input
//             type="file"
//             accept="image/jpeg,image/png,image/svg+xml"
//             onChange={handleImageUpload}
//             className="add-blog-form-upload-input"
//           />
//         </Form.Item>

//         <Form.Item wrapperCol={{ offset: 6, span: 18 }}>
//           <Button
//             type="primary"
//             htmlType="submit"
//             className="add-blog-form-button"
//           >
//             Add Blog Post
//           </Button>
//         </Form.Item>
//       </Form>
//     </div>
//   );
// };

// export default AddBlogForm;

import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import BlosgPostData from '../Blog Data/BlosgPostData';
import './AddBlog.css';

const AddBlogForm = () => {
  const [form] = Form.useForm();
  const [imageUrl, setImageUrl] = useState('');

  const onFinish = async values => {
    const newPost = {
      title: values.title,
      excerpt: values.excerpt,
      author: values.author,
      date: values.date,
      categories: values.categories.split(',').map(category => category.trim()),
      image: imageUrl
    };

    try {
      const response = await fetch('https://api.radiantrcm.com/blog-posts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(newPost)
      });

      if (response.ok) {
        const result = await response.json();
        // Add the new post to the data array
        BlosgPostData.push(newPost);
        console.log('Blog post added:', result); // Display the server response

        // Clear form fields
        form.resetFields();

        // Display success message
        message.success('Blog post added successfully');
      } else {
        const errorResponse = await response.json();
        console.error('Error response:', errorResponse);
        // Display error message if request fails
        message.error(`Failed to add blog post: ${response.statusText}`);
      }
    } catch (error) {
      console.error('Error adding blog post:', error);
      message.error('Failed to add blog post');
    }
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const handleImageUpload = async e => {
    const file = e.target.files[0];
    if (!file) return; // Check if file is present
  
    const isJpgOrPngOrSvg = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';
    if (!isJpgOrPngOrSvg) {
      message.error('You can only upload JPG/PNG/SVG file!');
      return false; // Return false to prevent upload
    }
  
    try {
      // Read the image file as a base64 string
      const base64String = await getBase64(file);
  
      // Set the image URL to the base64 string
      setImageUrl(base64String);
    } catch (error) {
      console.error('Error converting image to base64:', error);
    }
  
    return false; // Return false to prevent default upload behavior
  };

  // Function to convert image file to base64 string
  const getBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };

  return (
    <div className="add-blog-form-container">
      <h1 className="add-blog-form-title">Add Blog Post</h1>
      <Form
        form={form}
        name="add-blog-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      >
        <Form.Item
          label="Title"
          name="title"
          rules={[{ required: true, message: 'Please enter the title' }]}
          className="add-blog-form-item"
        >
          <Input className="add-blog-form-input" />
        </Form.Item>

        <Form.Item
          label="Excerpt"
          name="excerpt"
          rules={[{ required: true, message: 'Please enter the excerpt' }]}
          className="add-blog-form-item"
        >
          <Input.TextArea className="add-blog-form-input" />
        </Form.Item>

        <Form.Item
          label="Author"
          name="author"
          rules={[{ required: true, message: 'Please enter the author' }]}
          className="add-blog-form-item"
        >
          <Input className="add-blog-form-input" />
        </Form.Item>

        <Form.Item
          label="Date"
          name="date"
          rules={[{ required: true, message: 'Please select the date' }]}
          className="add-blog-form-item"
        >
          <Input type="date" className="add-blog-form-input" />
        </Form.Item>

        <Form.Item
          label="Categories"
          name="categories"
          rules={[{ required: true, message: 'Please enter at least one category' }]}
          className="add-blog-form-item"
        >
          <Input className="add-blog-form-input" />
        </Form.Item>

        <Form.Item
          label="Image"
          name="image"
          rules={[{ required: true, message: 'Please upload an image' }]}
          className="add-blog-form-item"
        >
          <input
            type="file"
            accept="image/jpeg,image/png,image/svg+xml"
            onChange={handleImageUpload}
            className="add-blog-form-upload-input"
          />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 6, span: 18 }}>
          <Button
            type="primary"
            htmlType="submit"
            className="add-blog-form-button"
          >
            Add Blog Post
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddBlogForm;
