import team from '../Asset/images/team.jpg';
const BlosgPostData = [
    {
      id: 1,
      title: 'First Blog Post',
      excerpt: 'This is a preview of the first blog post.',
      author: 'John Doe',
      date: 'March 1, 2024',
      categories: ['Technology', 'Programming'],
      image: team  // Add the URL or import the image as needed
    },
    {
      id: 2,
      title: 'Second Blog Post',
      excerpt: 'This is a preview of the second blog post.',
      author: 'Jane Smith',
      date: 'March 5, 2024',
      categories: ['Design', 'Web Development'],
      image: team  // Add the URL or import the image as needed
    },
    {
        id: 3,
        title: 'Third Blog Post',
        excerpt: 'This is a preview of the second blog post.',
        author: 'Jane Smith',
        date: 'March 5, 2024',
        categories: ['Design', 'Web Development'],
        image: team  // Add the URL or import the image as needed
      },
      {
        id: 4,
        title: 'Four Blog Post',
        excerpt: 'This is a preview of the second blog post.',
        author: 'Jane Smith',
        date: 'March 5, 2024',
        categories: ['Design', 'Web Development'],
        image: team  // Add the URL or import the image as needed
      },
      {
        id: 5,
        title: 'Fifth Blog Post',
        excerpt: 'This is a preview of the second blog post.',
        author: 'Jane Smith',
        date: 'March 5, 2024',
        categories: ['Design', 'Web Development'],
        image: team  // Add the URL or import the image as needed
      },
      {
        id: 6,
        title: 'sixth Blog Post',
        excerpt: 'This is a preview of the second blog post.',
        author: 'Jane Smith',
        date: 'March 5, 2024',
        categories: ['Design', 'Web Development'],
        image: team  // Add the URL or import the image as needed
      },
      {
        id: 7,
        title: 'Seventh Blog Post',
        excerpt: 'This is a preview of the first blog post.',
        author: 'John Doe',
        date: 'March 1, 2024',
        categories: ['Technology', 'Programming'],
        image: team  // Add the URL or import the image as needed
      },
      {
        id: 8,
        title: 'Eight Blog Post',
        excerpt: 'This is a preview of the second blog post.',
        author: 'Jane Smith',
        date: 'March 5, 2024',
        categories: ['Design', 'Web Development'],
        image: team  // Add the URL or import the image as needed
      },
      {
          id: 9,
          title: 'Nine Blog Post',
          excerpt: 'This is a preview of the second blog post.',
          author: 'Jane Smith',
          date: 'March 5, 2024',
          categories: ['Design', 'Web Development'],
          image: team // Add the URL or import the image as needed
        },
    // Add more blog posts as needed
  ];

export default BlosgPostData;