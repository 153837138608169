import React from "react";
import DifferenceCard from "../Cards/DifferenceCard";
import ValuesCard from "../Cards/ValuesCard";
import doctor from "../Asset/images/doctor.jpg";
import "./About.css";

const About = () => {
  return (
    <div>
      <div className="top-section">
        <h1 className="top-heading">The story behind Radiant RCM</h1>
      </div>
      <div className="about-section">
        <div className="about-text">
          <h2>About Our Company</h2>
          <p>
            Since its inception in 2013, Radiant RCM has illuminated the path in
            healthcare financial management. Armed with over a decade of
            expertise in healthcare IT and revenue cycle management, our
            dedicated professionals adeptly navigate the complexities of medical
            billing. Our tailored solutions encompass medical claims billing,
            precise AR recovery, and streamlined practice management. Our
            advanced medical software is trusted by healthcare providers across
            the nation, spanning numerous facilities and professionals. Radiant
            RCM stands as a reliable partner, guiding healthcare providers
            towards financial success.
          </p>
        </div>
        <div className="about-image">
          <img src={doctor} className="image" alt="doctor" />
        </div>
      </div>
      <div className="cards-section">
        <h2 className="card-heading">What make Us Different ?</h2>
        <DifferenceCard />
      </div>
      <div className="mission-vision-section">
        <h2>Mission & Vision</h2>
        <div className="mission-vision-container">
          <div className="mission">
            <h2 className="mission-head">Our Mission</h2>
            <p>
              Radiant RCM's mission is to empower healthcare providers with
              innovative and efficient financial management solutions. Our
              commitment is to navigate the complexities of medical billing,
              revenue cycle management, and practice efficiency, ensuring that
              healthcare organizations can focus on delivering exceptional
              patient care while maximizing their financial potential. We strive
              to be a trusted partner, dedicated to illuminating the path
              towards financial success for healthcare providers across the
              nation.
            </p>
          </div>
          <div className="vision">
            <h2 className="vision-head">Our Vision</h2>
            <p>
              We envision a healthcare landscape where providers can seamlessly
              navigate billing complexities, achieve optimal revenue outcomes,
              and allocate resources more effectively. Through cutting-edge
              technology, strategic partnerships, and continuous innovation, we
              aim to be the driving force that transforms financial challenges
              into opportunities for growth, ultimately shaping a brighter and
              more prosperous future for healthcare providers and the patients
              they serve.
            </p>
          </div>
        </div>
      </div>
      <div className="values-section">
        <h3 className="Values-heading">
          The values that drive everything we do
        </h3>
        <ValuesCard />
      </div>
    </div>
  );
};

export default About;
