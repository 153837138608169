import React from "react";
import { Form, Input, Button, Select, Checkbox } from "antd";
import hand from "../Asset/images/hand.jpg";
import "./RequestDemo.css";

const { Option } = Select;

const RequestDemo = () => {
  const [form] = Form.useForm();
  const onFinish = async (values) => {
    console.log("Received values:", values);
    try {
      const response = await fetch("https://api.radiantrcm.com/request-demo", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });
      if (!response.ok) {
        throw new Error("Failed to submit form");
      }
      console.log("Form submitted successfully");
      form.resetFields(); // Clear all input fields
    } catch (error) {
      console.error("Error submitting form:", error.message);
    }
  };

  return (
    <div className="container">
      <section className="section">
        <div>
          <h2 className="section-heading">Request A Demo</h2>
          <p className="section-paragraph">
            Thanks for your interest in Radiant RCM
          </p>
          <p className="section-paragraph2">
            We’re keen to know more about your practice! Whether you are a
            hospital, lab, imaging service or an independent medical provider,
            you need to deal with insurance claims recovery
          </p>
        </div>
        <div className="image-container">
          <img src={hand} alt="demo" />
        </div>
      </section>
      <section className="section2">
        <div>
          <h1 className="section2-heading">
            Thanks for your interest in Radiant RCM
          </h1>
          <h3 className="section2-heading">
            We’re keen to know more about your practice!
          </h3>
          <p className="section2-paragraph">
            Whether you are a hospital, lab, imaging service, or an independent
            medical provider, you need to deal with insurance claims recovery.
            Here is where Medcare MSO can help you generate more revenue.
          </p>
          <ul className="section2-paragraph">
            <li>Cut down billing costs</li>
            <li>Work with more simplicity and efficiency</li>
            <li>Drastically improve Revenue Cycle Management</li>
            <li>Accounts Receivable recovery assured</li>
          </ul>
          <p className="section2-paragraph">
            Contact our consultants to learn more at +1 918-383-6404
          </p>
          <div className="Important-Note">
            <h2 className="note-heading">Consent Note !</h2>
            <p className="note">
              By providing a telephone number and submitting this form you are
              consenting to be contacted by SMS text message. Message & data
              rates may apply. You can reply STOP to opt-out of further
              messaging.
            </p>
          </div>
        </div>
        <div className="contact-form-container">
          <div className="form">
            <h2 className="form-heading">Schedule a Demo</h2>
            <p className="form-paragraph">
              A member of our team will get back to you within 12 hours. For
              immediate service, give us a call at +1 918-383-6404
            </p>
            <Form
              form={form}
              name="demo-form"
              onFinish={onFinish}
              layout="vertical"
              className="form-container"
            >
              <div className="input-row">
                <div className="input-item">
                  <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                      { required: true, message: "Please enter your name" },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </div>
                <div className="input-item">
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      { required: true, message: "Please enter your email" },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </div>
              </div>
              <div className="input-row">
                <div className="input-item">
                  <Form.Item
                    label="Phone"
                    name="phone"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your phone number",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </div>
                <div className="input-item">
                  <Form.Item
                    label="Specialties"
                    name="specialties"
                    rules={[
                      { required: true, message: "Please select a specialty" },
                    ]}
                  >
                    <Select allowClear placeholder="Select a specialty">
                      <Option value="Cardiology">Cardiology</Option>
                      <Option value="Dermatology">Dermatology</Option>
                      <Option value="Rehab">Rehab</Option>
                      <Option value="Oncology">Oncology</Option>
                      <Option value="Psychiatric">Psychiatric</Option>
                      <Option value="Wound Care">Wound Care</Option>
                      <Option value="Pathology">Pathology</Option>
                      <Option value="Urology">Urology</Option>
                      <Option value="Other">Other</Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="input-row">
                <div className="input-item">
                  <Form.Item
                    label="Practice_size"
                    name="practice_size"
                    rules={[
                      {
                        required: true,
                        message: "Please select practice size",
                      },
                    ]}
                  >
                    <Select placeholder="Select practice size">
                      <Option value="1k to 10k">1k to 10k</Option>
                      <Option value="10k to 50k">10k to 50k</Option>
                      <Option value="50k or above">50k or above</Option>
                    </Select>
                  </Form.Item>
                </div>
                <div className="input-item">
                  <Form.Item
                    label="# of Providers"
                    name="providers"
                    rules={[
                      {
                        required: true,
                        message: "Please select number of providers",
                      },
                    ]}
                  >
                    <Select placeholder="Select number of providers">
                      <Option value="1-5">1-5</Option>
                      <Option value="6-10">6-10</Option>
                      <Option value="10 or above">10 or above</Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <Form.Item
                label="Difficulties you are facing in your practice"
                name="difficulties"
                style={{ marginBottom: "24px" }}
              >
                <Input.TextArea rows={4} />
              </Form.Item>
              <div className="checkboxes">
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please select checkbox before submitting",
                    },
                  ]}
                >
                  <Checkbox>
                    By providing a telephone number and submitting this form you
                    are consenting to be contacted by SMS text message. Message
                    & data rates may apply. You can reply STOP to opt-out of
                    further messaging.
                  </Checkbox>
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please select checkbox before submitting",
                    },
                  ]}
                >
                  <Checkbox>
                    Yes, I want to submit this form & agree to the term of use.
                  </Checkbox>
                </Form.Item>
              </div>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="submit-button"
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default RequestDemo;
