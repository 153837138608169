import React from "react";
import Revenue from "../Asset/images/Revenue.jpg";
import ServiceSection from "../Components/ServiceSection";
import "./Services.css";
import { useNavigate } from "react-router-dom";

const Services = () => {
  const navigate = useNavigate ();
  const handleClick = () => {
    navigate ('/contact');
  }
  return (
    <div className="service-page">
      <div className="service-hero-section">
        <h1 className="service-hero-heading">Medical Billing</h1>
        <p className="service-hero-paragraph">
          Facing billing challenges? Radiant RCM provides specialized medical
          billing solutions for practices of all sizes, ensuring accuracy,
          compliance, and revenue optimization. Join us in streamlining medical
          billing for small practices.
        </p>
      </div>
      <div className="description-section">
        <div className="description-text">
          <h2>
            Strengthening Profitability and Practice Management in Medical
            Billing
          </h2>
          <p>
            We empathize with the challenges you face in healthcare billing. Our
            comprehensive services boost profitability and ease burdensome tasks
            like insurance verification and pre-approvals. Outsourcing to us
            saves money and minimizes admin loads.
          </p>
        </div>
        <div className="description-image">
          <img src={Revenue} alt="Service" className="revenue-cycle" />
        </div>
      </div>
      <div className="revenue-card-section">
        <div className="revenue-card">
          <h3>Fast Filling</h3>
          <p>
            Efficiently fill out and submit medical forms to expedite the
            billing process.
          </p>
        </div>
        <div className="revenue-card">
          <h3>Clean Claims</h3>
          <p>
            Ensure accuracy and completeness in claims submissions for swift
            processing.
          </p>
        </div>
        <div className="revenue-card">
          <h3>Tracking System</h3>
          <p>
            Utilize advanced tracking systems to monitor the progress of billing
            submissions.
          </p>
        </div>
        <div className="revenue-card">
          <h3>Rigorous Follow-up</h3>
          <p>
            Implement rigorous follow-up procedures to resolve billing
            discrepancies promptly.
          </p>
        </div>
        <div className="revenue-card">
          <h3>A/R Management</h3>
          <p>
            Efficiently manage accounts receivable to optimize revenue streams.
          </p>
        </div>
        <div className="revenue-card">
          <h3>Seeking For Different Diagnosis</h3>
          <p>
            Explore diverse diagnosis options to maximize reimbursement
            opportunities.
          </p>
          <button onClick={handleClick}>Contact US</button>
        </div>
      </div>
      <div className="services-section">
        <ServiceSection showButton={false} />
      </div>
    </div>
  );
};

export default Services;
