import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./Components/Header";
import Home from "./Pages/Home";
import Footer from "./Components/Footer";
import About from "./Pages/About";
import Services from "./Pages/Services";
import ScrollTopButton from "./Button/ScrollTopButton";
import Blog from "./Pages/Blog";
import AddBlogForm from "./Pages/AddBlog";
import Contact from "./Pages/Contact";
import RequestDemo from "./Pages/RequestDemo";
import LoginForm from "./Pages/LoginForm";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import FullBlogPost from "./Pages/FullBlogPost";

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    // Check if the user was previously logged in
    const loggedIn = localStorage.getItem("isLoggedIn") === "true";
    setIsLoggedIn(loggedIn);
  }, []);

  const toggleLogin = () => {
    // Toggle the login state and update local storage
    setIsLoggedIn(!isLoggedIn);
    localStorage.setItem("isLoggedIn", !isLoggedIn);
  };

  return (
    <BrowserRouter>
      <Header isLoggedIn={isLoggedIn} toggleLogin={toggleLogin} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/blog" element={<Blog isLoggedIn={isLoggedIn} currentUser={currentUser} />} />
        <Route path="/blog/:id" element={<FullBlogPost />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/requestdemo" element={<RequestDemo />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/addblog" element={<AddBlogForm />} />
        <Route
          path="/loginform"
          element={<LoginForm toggleLogin={toggleLogin} />}
        />
      </Routes>
      <ScrollTopButton />
      <Footer />
    </BrowserRouter>
  );
};

export default App;

