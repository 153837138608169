import React from "react";
import { Modal, Form, Input, Select, Button, Checkbox } from "antd";

const { Option } = Select;

const RequestDemoFormModal = ({ visible, onCancel, onFinish, form }) => {
  return (
    <Modal
      title="Schedule Demo"
      open={visible}
      onCancel={onCancel}
      footer={null}
    >
      <Form name="demo-form" onFinish={onFinish} layout="vertical" form={form}>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please enter your name" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: "Please enter your email" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Phone"
          name="phone"
          rules={[
            { required: true, message: "Please enter your phone number" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Specialties"
          name="specialties"
          rules={[{ required: true, message: "Please select a specialty" }]}
        >
          <Select allowClear placeholder="Select a specialty">
            <Option value="Cardiology">Cardiology</Option>
            <Option value="Dermatology">Dermatology</Option>
            <Option value="Rehab">Rehab</Option>
            <Option value="Oncology">Oncology</Option>
            <Option value="Psychiatric">Psychiatric</Option>
            <Option value="Wound Care">Wound Care</Option>
            <Option value="Pathology">Pathology</Option>
            <Option value="Urology">Urology</Option>
            <Option value="Other">Other</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Practice_size"
          name="practice_size"
          rules={[{ required: true, message: "Please select practice size" }]}
        >
          <Select placeholder="Select practice size">
            <Option value="1k to 10k">1k to 10k</Option>
            <Option value="10k to 50k">10k to 50k</Option>
            <Option value="50k or above">50k or above</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="# of Providers"
          name="providers"
          rules={[
            { required: true, message: "Please select number of providers" },
          ]}
        >
          <Select placeholder="Select number of providers">
            <Option value="1-5">1-5</Option>
            <Option value="6-10">6-10</Option>
            <Option value="10 or above">10 or above</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Difficulties you are facing in your practice"
          name="difficulties"
          style={{ marginBottom: "24px" }}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Please select checkbox before submitting",
            },
          ]}
        >
          <Checkbox>
            By providing a telephone number and submitting this form you are
            consenting to be contacted by SMS text message. Message & data rates
            may apply. You can reply STOP to opt-out of further messaging.
          </Checkbox>
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Please select checkbox before submitting",
            },
          ]}
        >
          <Checkbox>
            Yes, I want to submit this form & agree to the term of use.
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ background: "black" }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default RequestDemoFormModal;
