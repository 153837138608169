import React from "react";
import { Form, Input, Button, Checkbox } from "antd";
import { PhoneOutlined } from "@ant-design/icons";
import { FaCommentSms } from "react-icons/fa6";
import contact from "../Asset/images/contact.jpg";
import "./Contact.css";

const Contact = () => {
  const [form] = Form.useForm();
  const onFinish = async (values) => {
    try {
      // Make a POST request to the backend API
      const response = await fetch("https://api.radiantrcm.com/contact-form", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });

      if (!response.ok) {
        throw new Error("Failed to submit form");
      }

      const data = await response.json();
      console.log("Response:", data);
      form.resetFields();
    } catch (error) {
      // Handle error, display error message or perform any other action
      console.error("Error:", error);
    }
  };

  return (
    <div className="contact-container">
      {/* Connect with Medcare MSO Section */}
      <section className="connect-section">
        <div className="connect-info">
          <h2>Connect with Radiant RCM</h2>
          <p>
            We would be happy to answer any questions and explore how our
            services can support your business. If you tell us a bit about your
            interests, we’ll make sure we get you the best contact person on our
            end.
          </p>
          <p>
            If you’d like to talk to someone now, give us a call or{" "}
            <FaCommentSms /> sms at <PhoneOutlined /> +1 918-383-6404.
          </p>
        </div>
        <div className="connect-image">
          <img src={contact} alt="Contact Us" />
        </div>
      </section>

      {/* Tell Us Where to Reach You Section */}
      <section className="reach-section">
        <div className="reach-info">
          <h2>Tell Us Where to Reach You</h2>
          <p>A member of our team will get back to you within 12 hours.</p>
          <p>
            For immediate service, give us a call or <FaCommentSms /> sms at{" "}
            <PhoneOutlined /> +1 918-383-6404.
          </p>
        </div>
        <div className="reach-form">
          <Form
            name="contact-form"
            onFinish={onFinish}
            layout="vertical"
            style={{
              border: "1px solid #ccc",
              padding: "20px",
              borderRadius: "8px",
            }}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "Please enter your name" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: "Please enter your email" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Phone"
              name="phone"
              rules={[
                { required: true, message: "Please enter your phone number" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Subject"
              name="subject"
              rules={[{ required: true, message: "Please enter the subject" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Your Message"
              name="message"
              rules={[{ required: true, message: "Please enter your message" }]}
            >
              <Input.TextArea />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please select checkbox before submitting",
                },
              ]}
            >
              <Checkbox>
                By providing a telephone number and submitting this form you are
                consenting to be contacted by SMS text message. Message & data
                rates may apply. You can reply STOP to opt-out of further
                messaging.
              </Checkbox>
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please select checkbox before submitting",
                },
              ]}
            >
              <Checkbox>
                Yes, I want to submit this form & agree to the term of use.
              </Checkbox>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ backgroundColor: "black", border: "none" }}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </section>

      {/* Address Section */}
      <section className="address-section">
        <div className="address-info">
          <h2>Address</h2>
          <p>3121 West Hartford St, , Broken Arrow, OK, United States</p>
          {/* <p>5678 Street Name, City, State, Zip Code</p> */}
        </div>
        <div className="map-section">
          <h2>Find Us on Map</h2>
          <iframe
            title="Google Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3346.424333459648!2d-95.81580508476296!3d36.05778918010055!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87b693d5d076693d%3A0x7d2b2dd0cc19483f!2s3121%20W%20Hartford%20St%2C%20Broken%20Arrow%2C%20OK%2074072%2C%20USA!5e0!3m2!1sen!2sin!4v1636742738005!5m2!1sen!2sin"
            width="600"
            height="450"
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </section>

      {/* Contact Info Section */}
      <section className="contact-info-section">
        <div className="contact-info">
          <h2>Contact Us</h2>
          <p>Phone: +1 918-383-6404</p>
          <p>Email: info@radiantrcm.com</p>
        </div>
      </section>
    </div>
  );
};

export default Contact;
