import React, { useState, useEffect } from "react";
import { Input, Button, Modal, Form, message } from "antd";
import { SearchOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import svg from "../Asset/images/svg.jpg";
import FullBlogPost from "./FullBlogPost";
import "./Blog.css";

const Blog = ({isLoggedIn}) => {
  const [searchValue, setSearchValue] = useState("");
  const [visiblePosts, setVisiblePosts] = useState(6);
  const postsPerPage = 3;
  const [selectedPostId, setSelectedPostId] = useState(null);
  const [blogPosts, setBlogPosts] = useState([]);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editedPost, setEditedPost] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchBlogPosts();
  }, []);

  const fetchBlogPosts = async () => {
    try {
      const response = await fetch("https://api.radiantrcm.com/all-blog");
      if (response.ok) {
        const data = await response.json();
        setBlogPosts(data);
      } else {
        console.error("Failed to fetch blog posts");
      }
    } catch (error) {
      console.error("Error fetching blog posts:", error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const loadMorePosts = () => {
    setVisiblePosts((prevVisiblePosts) => prevVisiblePosts + postsPerPage);
  };

  const handlePostClick = (postId) => {
    setSelectedPostId(postId);
  };

  const handleEditPost = (post) => {
    setEditedPost(post);
    setEditModalVisible(true);
    form.setFieldsValue(post);
  };

  const handleDeletePost = async (postId) => {
    try {
      const response = await fetch(`https://api.radiantrcm.com/delete-posts/${postId}`, {
        method: "DELETE",
      });
      if (response.ok) {
        setBlogPosts((prevPosts) => prevPosts.filter((post) => post.id !== postId));
        message.success("Blog post deleted successfully");
      } else {
        message.error("Failed to delete blog post");
      }
    } catch (error) {
      console.error("Error deleting blog post:", error);
      message.error("Failed to delete blog post");
    }
  };

  const onFinishEdit = async (values) => {
    try {
      const response = await fetch(`https://api.radiantrcm.com/edit-posts/${editedPost.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });
      if (response.ok) {
        const updatedPost = { ...editedPost, ...values };
        setBlogPosts((prevPosts) =>
          prevPosts.map((post) => (post.id === updatedPost.id ? updatedPost : post))
        );
        message.success("Blog post updated successfully");
        setEditModalVisible(false);
      } else {
        message.error("Failed to update blog post");
      }
    } catch (error) {
      console.error("Error updating blog post:", error);
      message.error("Failed to update blog post");
    }
  };

  return (
    <div className="blog-container">
      {selectedPostId ? (
        <FullBlogPost postId={selectedPostId} />
      ) : (
        <>
          <div className="blog-hero-section">
            <div>
              <h1 className="blog-hero-heading">Blog</h1>
              <p className="blog-hero-paragraph">
                Explore our latest blog posts
              </p>
              <Input
                placeholder="Search blog posts"
                suffix={<SearchOutlined style={{ fontSize: 24 }} />}
                value={searchValue}
                onChange={handleSearchChange}
                style={{ width: 300, height: 45, borderRadius: 8 }}
              />
            </div>
            <div className="hero-svg">
              <img src={svg} alt="hero-image" className="svg" />
            </div>
          </div>
          <div className="blog-posts-section">
            {blogPosts
              .filter((post) =>
                post.title.toLowerCase().includes(searchValue.toLowerCase())
              )
              .slice(0, visiblePosts)
              .map((post) => (
                <div
                  key={post.id}
                  className="blog-post-card"
                  onClick={() => handlePostClick(post.id)}
                >
                  <img
                    src={`data:image/jpeg;base64,${post.image}`}
                    alt={post.title}
                    className="blog-post-image"
                  />
                  <div className="blog-post-details">
                    <h2>{post.title}</h2>
                    <p className="excerpt">{post.excerpt}</p>
                    <p>Author: {post.author}</p>
                    <p>Date: {post.date}</p>
                    <p>
                      Categories:{" "}
                      {Array.isArray(post.categories)
                        ? post.categories.join(", ")
                        : post.categories}
                    </p>
                    <div className="blog-post-actions">
                    {isLoggedIn && ( // Render only if user is logged in
                    <>
                      <Button
                        icon={<EditOutlined />}
                        onClick={() => handleEditPost(post)}
                        className="edit-button"
                      >
                        Edit
                      </Button>
                      <Button
                        icon={<DeleteOutlined />}
                        onClick={() => handleDeletePost(post.id)}
                        className="delete-button"
                      >
                        Delete
                      </Button>
                    </>
                  )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
          {visiblePosts < blogPosts.length && (
            <div className="load-more-button-container">
              <Button onClick={loadMorePosts}>Load More</Button>
            </div>
          )}
        </>
      )}
      <Modal
        title="Edit Blog Post"
        open={editModalVisible}
        onCancel={() => setEditModalVisible(false)}
        footer={null}
      >
        <Form form={form} onFinish={onFinishEdit} initialValues={editedPost}>
          <Form.Item name="title" label="Title">
            <Input />
          </Form.Item>
          <Form.Item name="excerpt" label="Excerpt"  rules={[{ max: 4000, message: 'Excerpt cannot exceed 4000 characters' }]}>
            <Input.TextArea maxLength={4000} />
          </Form.Item>
          <Form.Item name="author" label="Author">
            <Input />
          </Form.Item>
          <Form.Item name="date" label="Date">
            <Input type="date" />
          </Form.Item>
          <Form.Item name="categories" label="Categories">
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save Changes
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Blog;