import React, { useState } from 'react';
import { Form } from 'antd'; 
import { PiClockCountdownFill } from "react-icons/pi";
import { FaCircleDollarToSlot, FaWpforms} from "react-icons/fa6";
import { BsGraphUpArrow, BsCollectionFill } from "react-icons/bs";
// import { FaInnosoft } from "react-icons/fa";
// import { GrCycle } from "react-icons/gr";
import team from "../Asset/images/team.jpg";
import "./Home.css";
import BenifitCard from "../Components/BenifitCard";
import ServiceSection from "../Components/ServiceSection";
import Testimonial from "../Components/Testimonial";
import Specialties from "../Components/Specialties";
import { useNavigate } from "react-router-dom";
import RequestDemoFormModal from '../Components/RequestDemoFormModal';

const Home = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const navigate = useNavigate('');
  const [form] = Form.useForm(); 

  const handleContact = () => {
    navigate('/contact');
  }

  const showModal = () => {
    setModalVisible(true);
  };

  const handleModalCancel = () => {
    setModalVisible(false);
  };

  const handleFormSubmit = async (values) => {
    console.log("Received values:", values);
    try {
        const response = await fetch('https://api.radiantrcm.com/request-demo', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(values)
        });
        if (!response.ok) {
            throw new Error('Failed to submit form');
        }
        console.log('Form submitted successfully');
        form.resetFields(); 
        setModalVisible(false);
    } catch (error) {
        console.error('Error submitting form:', error.message);
    }
};
  return (
    <div>
      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-content">
          {/* <h1>Welcome to Our Medical Billing Service</h1> */}
          <div className="hero-buttons">
            <button className="contact-btn" onClick={handleContact}>Contact Us</button>
            <button className="schedule-btn"  onClick={showModal}>Schedule Demo</button>
          </div>
          {/* Add any other content for the hero section */}
        </div>
      </section>
      {/* Key Performance Indicators Banner */}
      <div className="kpis-banner">
        <div className="kpis-header">
          <p className="kpis-title">Our Key Performance Indicators</p>
        </div>
        <div className="kpis-content">
          <div className="kpi-item">
            <PiClockCountdownFill />
            <h4>7-14</h4>
            <p className='desciption'>Days Turnaround Time</p>
          </div>
          <div className="kpi-item">
            <FaCircleDollarToSlot />
            <h4>30%</h4>
            <p className='desciption'>Reduction in A/R</p>
          </div>
          <div className="kpi-item">
            <BsGraphUpArrow />
            <h4>15-20 %</h4>
            <p className='desciption'>Revenue Increase</p>
          </div>
          <div className="kpi-item">
            <BsCollectionFill />
            <h4>90 % +</h4>
            <p className='desciption'>Collection Ratio</p>
          </div>
          <div className="kpi-item">
            <FaWpforms />
            <h4>95 %</h4>
            <p className='desciption'>First Pass Clean Claims Rate</p>
          </div>
        </div>
      </div>
      <section className="feature-section">
        <div className="image-container">
          {/* Image */}
          <img src={team} alt="Description of the image" />

          {/* Cards */}
          {/* <div className="card-overlay">
            <div className="card">
              <div className="card-icon">
                <GrCycle />
              </div>
              <div className="card-content">
                <h3>Revenue Cycle Management</h3>
                <p>Experienced in over 40 specialties</p>
              </div>
            </div>
            <div className="card">
              <div className="card-icon">
                <FaUserDoctor />
              </div>
              <div className="card-content">
                <h3>Practice Management</h3>
                <p>Supporting all phases of the revenue cycle</p>
              </div>
            </div>
            <div className="card">
              <div className="card-icon">
                <FaInnosoft />
              </div>
              <div className="card-content">
                <h3>Innovative Software</h3>
                <p>
                  Continuously updated to ensure current coding and claim
                  procedures are used
                </p>
              </div>
            </div>
          </div> */}
        </div>

        <div className="content-container">
          <h2>
            Stay Compliant and Reduce Denials with Our Revenue Cycle Management
            Services
          </h2>
          <p>
            Radiant RCM is highly regarded within the medical billing industry
            for our unwavering dedication to delivering effective solutions
            tailored to the needs of healthcare providers, including physicians,
            laboratories, and various medical organizations. We specialize in
            offering personalized insurance and patient billing services, along
            with comprehensive healthcare recovery support and practice
            management solutions, catering to entities of diverse sizes and
            specialties. Our primary objective is to streamline the revenue
            cycle management process for healthcare practices, granting them the
            financial freedom they rightfully deserve. Through our innovative
            medical software, we've simplified every facet of practice
            management and patient interaction. Moreover, our software is
            designed for seamless integration, ensuring a swift transition for
            our clients, often within a matter of days, unlike the lengthy
            processes typically associated with other software systems.
          </p>
        </div>
      </section>
      <BenifitCard />
      <ServiceSection showButton={true} />
      <Testimonial />
      <Specialties />
      <RequestDemoFormModal
        visible={modalVisible}
        onCancel={handleModalCancel}
        onFinish={handleFormSubmit}
        form={form}
      />
    </div>
  );
};

export default Home;
