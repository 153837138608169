import React from "react";
import './Specialties.css';
import Cardiology from '../Asset/icons/Cardiology.png';
import dermatology from '../Asset/icons/dermatology.png';
import Rehab from '../Asset/icons/Rehab.png';
import Oncology from '../Asset/icons/Oncology.png';
import Phsyciatric from '../Asset/icons/Phsyciatric.png';
import WoundCare from '../Asset/icons/Woundcare.png';
import Pathology from '../Asset/icons/Pathology.png';
import Urology from '../Asset/icons/Urology.png';

const Specialties = () => {
  const specialties = [
    { id: 1, icon: Cardiology, name: "Cardiology" },
    { id: 2, icon: dermatology, name: "Dermatology" },
    { id: 3, icon: Rehab, name: "Rehab" },
    { id: 4, icon: Oncology, name: "Oncology" },
    { id: 5, icon: Phsyciatric, name: "Psychiatric" },
    { id: 6, icon: WoundCare, name: "Wound Care" },
    { id: 7, icon: Pathology, name: "Pathology" },
    { id: 8, icon: Urology, name: "Urology" },
  ];

  return (
    <section className="specialties-section">
      <h2>Our Specialties</h2>
      <div className="specialties-container">
        {/* Map over specialties array to render each specialty */}
        {specialties.map((specialty) => (
          <div key={specialty.id} className="specialty-card">
            <img
              src={specialty.icon}
              alt={specialty.name}
              className="specialty-icon"
            />
            <p className="specialty-name">{specialty.name}</p>
          </div>
        ))}
      </div>
      {/* <button className="view-all-button">View All Specialties</button> */}
    </section>
  );
};

export default Specialties;
